import React, { Component } from 'react'
import Container from '../Container/Container'
import HomeRowBigElement from './HomeRowBigElement/HomeRowBigElement'
import text from '../../lang/it/home.json'
import './home-row-big.scss'
import Gallery from '../Gallery/Gallery'
import getLocationGalleryImages from '../../helpers/getLocationGalleryImages'
import LogoBanner from '../LogoBanner/LogoBanner'

const settings = {
  dots: true,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 720,
      settings: { slidesToShow: 2 }
    },
    {
      breakpoint: 480,
      settings: { slidesToShow: 1 }
    },
  ]
}

class HomeRowBig extends Component {
  render () {
    const images = getLocationGalleryImages()

    return (
      <div className='home-row-big'>
        <Container>
          <HomeRowBigElement
            title={text.rows[1].title}
            body={text.rows[1].body}
            buttonLink={text.rows[1].buttonLink}
            buttonText={text.rows[1].buttonText}
            key={1}
          ></HomeRowBigElement>
          {/* <HomeRowBigElement
            title={text.rows[4].title}
            body={text.rows[4].body}
            reversed={true}
            key={2}
          ></HomeRowBigElement> */}
        </Container>
        <Container>
          <Gallery settings={settings} images={images}></Gallery>
        </Container>
        <Container>
          <LogoBanner></LogoBanner>
        </Container>
      </div>
    )
  }
}

export default HomeRowBig
