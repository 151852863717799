import React, { SyntheticEvent } from 'react'
import Slider, { Settings } from 'react-slick'
import ContentSliderProps from './ContentSliderProps'
import sliderSettings from '../../helpers/sliderSettings'

import './content-slider.scss'

function pad (value: number) {
  return ('0' + value).slice(-2)
}

class ContentGallery extends React.Component {

  private settings: Settings = {
    ... sliderSettings(),
    beforeChange: this.onBeforeChange.bind(this),
    onInit: this.onInit.bind(this)
  }

  private slider: any = null

  state = {
    currentSlide: pad(1),
    totalSlides: pad(0)
  }

  constructor (props: ContentSliderProps) {
    super(props)

    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
  }

  private onInit () {
    setTimeout(()=>{
      this.setState({
        totalSlides: pad(this.slider.props.children.length)
      })
    }, 300)
  }

  private onBeforeChange (_: number, newValue: number) {
    this.setState({
      currentSlide: pad(newValue + 1)
    })
  }

  next (e: SyntheticEvent) {
    this.slider?.slickNext()
    e.preventDefault()
  }

  prev (e: SyntheticEvent) {
    this.slider?.slickPrev()
    e.preventDefault()
  }

  render () {
    return (
      <div className='content-slider'>
        <div className='content-slider__navigation'>
          <hr />
          <span className='content-slider__navigation__slides'>{this.state.currentSlide} - {this.state.totalSlides}</span>
          <div className='content-slider__navigation__arrows'>
            <a
              onClick={this.prev}
              href='#'
              className='content-slider__navigation__arrow-left'
            >
              {' '}
              &lsaquo;{' '}
            </a>
            <a
              onClick={this.next}
              href='#'
              className='content-slider__navigation__arrow-right'
            >
              &rsaquo;
            </a>
          </div>
        </div>
        <Slider ref={(c: JSX.Element) => (this.slider = c)} {...this.settings}>
          {this.props.children}
        </Slider>
      </div>
    )
  }
}

export default ContentGallery
