import React, { Component } from 'react'
import Container from '../Container/Container'
import HomeRowMediumElement from './HomeRowMediumElement/HomeRowMediumElement'
import text from '../../lang/it/home.json'
import './home-row-medium.scss'

class HomeRowMedium extends Component {
  render () {
    return (
      <div className='home-row-medium'>
        <Container>
          <HomeRowMediumElement
            title={text.rows[2].title}
            body={text.rows[2].body}
            image='/images/home/diretta-streaming.jpg'
            key={1}
          ></HomeRowMediumElement>
          <HomeRowMediumElement
            title={text.rows[3].title}
            body={text.rows[3].body}
            image='/images/home/ristorazione-in-house.jpeg'
            reversed={true}
            key={2}
          ></HomeRowMediumElement>
        </Container>
      </div>
    )
  }
}

export default HomeRowMedium
