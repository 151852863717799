import React from 'react'
import { Helmet } from 'react-helmet'
import ContactForm from '../components/ContactForm/ContactForm'
import GridLogo from '../components/GridLogo/GridLogo'
import HomeContentSlider from '../components/HomeContentSlider/HomeContentSlider'
import HomeRowBig from '../components/HomeRowBig/HomeRowBig'
import HomeRowMedium from '../components/HomeRowMedium/HomeRowMedium'
import Layout from '../components/Layout'
import MediaBox from '../components/MediaBox/MediaBox'
import ParallaxMedia from '../components/ParallaxMedia/ParallaxMedia'
import isMobile from '../helpers/isMobile'
import text from '../lang/it/home.json'
import LocationBotSection from '../components/Location/LocationBotSection/LocationBotSection'

// markup
const Home = () => {
  let imageTop = '../images/home/homepage-top-mobile.jpg'
  let image = '../images/home/homepage-bottom-mobile.jpg'

  if (!isMobile()) {
    imageTop = '../images/home/homepage-top-desktop.jpg'
    image = '../images/home/homepage-bottom.jpg'
  }

  return (
    <Layout className='home'>
      <Helmet>
        <title>Home | Kready</title>
        <link
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
          rel='stylesheet'
          type='text/css'
        />
        <link
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
          rel='stylesheet'
          type='text/css'
        />
      </Helmet>
      <MediaBox
        image={imageTop}
        title={text.title}
        buttonText={text.titleCta}
        buttonLink='/location/'
        layer='desktop'
      ></MediaBox>
      <HomeContentSlider />
      <HomeRowBig></HomeRowBig>
      <GridLogo></GridLogo>
      <HomeRowMedium></HomeRowMedium>
      <ParallaxMedia image={image}></ParallaxMedia>
      <LocationBotSection />
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default Home
