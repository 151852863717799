import React, { Component } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import ButtonPrimary from '../../ButtonPrimary/ButtonPrimary'

import HomeRowBigElementProps from './HomeRowBigElementProps'
import './home-row-big-element.scss'

class HomeRowBigElement extends Component {

  static props: HomeRowBigElementProps = {
    title: '',
    body: '',
    buttonText: '',
    buttonLink: '',
    reversed: false
  }

  state = {
    reversed: false
  }

  constructor (params: HomeRowBigElementProps) {
    super(params)
    this.state.reversed = params.reversed
  }

  private getClassName () {
    const classes = ['home-row-big-element']

    if (this.state.reversed) {
      classes.push('home-row-big-element--reversed')
    }

    return classes.join(' ')
  }

  render () {
    return (
      <div className={this.getClassName()}>
        <div className='home-row-big-element__cover' data-aos='fade-up'>
          <Link to={this.props.buttonLink}>
            <StaticImage
              src='../../../../static/images/home/homepage-location.jpg'
              alt='name'
              placeholder='blurred'
              layout='fixed'
              width={350}
              height={550}
            ></StaticImage>
          </Link>
        </div>
        <div className='home-row-big-element__content' data-aos='fade-in' data-aos-delay='300'>
          <div className='home-row-big-element__title'>
            <h3>{this.props.title}</h3>
          </div>
          <p>
            {this.props.body}
          </p>
          { this.props.buttonText && <ButtonPrimary href={this.props.buttonLink || ''}>{this.props.buttonText}</ButtonPrimary> }
        </div>
      </div>
    )
  }
}

export default HomeRowBigElement
