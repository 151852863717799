
import React from 'react'
import ButtonPrimary from '../../ButtonPrimary/ButtonPrimary';
import ContentSliderSlideProps from './ContentSliderSlideProps';
import './content-slider-slide.scss'

export default function ContentGallery ({ title, text, buttonText, buttonLink, ...props }: ContentSliderSlideProps) {
  return (<div className="content-slider-slide" {...props}>
    <div className="content-slider-slide__title">
      <h3>{title}</h3>
    </div>
    <div className="content-slider-slide__text">
      <p>{text}</p>
    </div>
    {buttonLink && buttonText && <ButtonPrimary href={buttonLink}>{buttonText}</ButtonPrimary>}
  </div>)
}
