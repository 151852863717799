import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import './logo-banner.scss'

interface LogoBannerProps {
  text?: string
}
const LogoBanner = ({ text }: LogoBannerProps) => {
  const className = `logo-banner ${
    text ? 'logo-banner--with-text' : ''
  }`

  return (
    <div className={className} data-aos='fade'>
      <StaticImage alt='Gadames 57 Logo' src='./logo-banner.png'></StaticImage>
      {text && (
        <p dangerouslySetInnerHTML={{ __html: text || '' }}></p>
      )}
    </div>
  )
}

export default LogoBanner
