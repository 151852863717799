import React from 'react'
import ContentSlider from '../ContentSlider/ContentSlider'
import ContentSliderSlide from '../ContentSlider/ContentSliderSlide/ContentSliderSlide'
import Container from '../Container/Container'
import text from '../../lang/it/home.json'

export default function HomeContentSlider () {
  return (
    <Container>
      <ContentSlider>
        <ContentSliderSlide
          key={1}
          title={text.slider[1].title}
          text={text.slider[1].body}
        ></ContentSliderSlide>
        <ContentSliderSlide
          key={2}
          title={text.slider[2].title}
          text={text.slider[2].body}
        ></ContentSliderSlide>
      </ContentSlider>
    </Container>
  )
}
