import React, { Component } from 'react'
import ButtonPrimary from '../../ButtonPrimary/ButtonPrimary'

import './home-row-medium-element.scss'
import HomeRowMediumElementProps from './HomeRowMediumElementProps'

class HomeRowMediumElement extends Component {
  state = {
    reversed: false
  }

  static props: HomeRowMediumElementProps = {
    title: '',
    body: '',
    buttonText: '',
    buttonLink: '/',
    reversed: false,
    image: ''
  }

  constructor (params: HomeRowMediumElementProps) {
    super(params)
    this.state.reversed = params.reversed
  }

  private getClassName () {
    const classes = ['home-row-medium-element']

    if (this.state.reversed) {
      classes.push('home-row-medium-element--reversed')
    }

    return classes.join(' ')
  }

  render () {
    const dataAosCover = this.state.reversed ? 'fade-left' : 'fade-right'
    const dataAosContent = this.state.reversed ? 'fade-right' : 'fade-left'
    const backgroundImage = `url(${this.props.image})`
    return (
      <div className={this.getClassName()}>
        <div className='home-row-medium-element__cover' data-aos={dataAosCover}>
          <a href={this.props.buttonLink} style={{ cursor: 'default' }}>
            <div className='home-row-medium-element__cover__img' style={{ backgroundImage }}></div>
          </a>
        </div>
        <div className='home-row-medium-element__content' data-aos={dataAosContent}>
          <div className='home-row-medium-element__title'>
            <h3>{this.props.title}</h3>
          </div>
          <p>{this.props.body}</p>
          {this.props.buttonText && <ButtonPrimary href={this.props.buttonLink}>{this.props.buttonText}</ButtonPrimary>}
        </div>
      </div>
    )
  }
}

export default HomeRowMediumElement
